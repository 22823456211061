.custom-login-container *{
  box-sizing: revert;
}

.custom-login-container h1,h2,h3,h4,h5{
  margin: revert;
}

.custom-login-container {
  margin-top: 20px;
  margin-left: 40px;
}
