.menu-container {
  width: 160px;
  top: 0;
  left: 0;
  position: absolute;
  background: rgb(21,21,21);
  z-index: 999;
  height: 100%;
}

.menu-item {
  padding: 15px 0;
  text-align: center;
  border-bottom: solid 1px rgb(34,35,35);
}

.last-menu-item {
  border-bottom: solid 1px #ccc;
}

.menu-item a{
  color: #aaa;
  text-decoration: none;
  font-size: 14px;
}

.menu-item.highlighted a{
  color: white;
}

a {
  cursor: pointer;
}

.account-name {
  padding: 40px 5px 0;
  font-size: 11px;
  color: #aaa;
  text-align: center;
  line-height: 16px;
  overflow-wrap: break-word;
}
