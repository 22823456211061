import Logger from './Logger'

export function setAdvisorCustomLogin () {
  const query = new URLSearchParams(location.search)
  const advisorId = query.get('advisor_id')

  if (advisorId) {
    localStorage.setItem('customLoginAdvisorId', advisorId)
    Logger.info('Set customLoginAdvisorId', advisorId)
  }
}

export function getAdvisorCustomLogin () {
  return localStorage.getItem('customLoginAdvisorId')
}

export function clearAdvisorCustomLogin () {
  return localStorage.removeItem('customLoginAdvisorId')
}
