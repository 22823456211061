import React, { useState, useRef } from 'react'
import '../SharedCss/alert.css'
import '../SharedCss/form.css'
import { Redirect, Link } from 'react-router-dom'
import Flash from '../Flash'
import Logger from '../Logger'
import ALERT_TYPES from '../SharedConstants/AlertTypes'
import Footer from '../SharedComponents/Footer'
import LoadingBar from 'react-top-loading-bar'
import { checkCognitoStatus } from '../ExternalHosts/mono'
require('dotenv').config()

export default function ForgotPasswordForm (props) {
  const { CognitoIdentityLib } = props
  const [email, setEmail] = useState('')
  const [alertType, setAlertType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [redirect, setRedirect] = useState(false)
  const loadingRef = useRef(null)

  const handleSubmit = (e) => {
    Logger.start('Submit Forgot Password', { email: email })
    e.preventDefault()
    setAlertType('')
    loadingRef.current.continuousStart()
    const userPool = new CognitoIdentityLib.CognitoUserPool({
      UserPoolId: process.env.USER_POOL_ID,
      ClientId: process.env.COGNITO_APP_ID
    })

    const userData = {
      Username: email,
      Pool: userPool
    }

    const cognitoUser = new CognitoIdentityLib.CognitoUser(userData)
    const contactUsMessage = 'If your email address is registered in our system, you will receive an email from Blueleaf. Need help? Contact us <a href="mailto:vipsupport@blueleaf.com">here</a>.'

    checkCognitoStatus(email).then((response) => {
      if (response.status === 'UNCONFIRMED') {
        loadingRef.current.complete()
        Flash.setMessage(contactUsMessage)
        setRedirect(true)
        return
      }

      if (response.status === 'NOT_FOUND') {
        loadingRef.current.complete()
        Flash.setMessage(contactUsMessage)
        setRedirect(true)
        return
      }

      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          loadingRef.current.complete()
          Logger.done('Submit Forgot Password', { email: email })
          Flash.setMessage(contactUsMessage)
          setRedirect(true)
        },
        onFailure: (err) => {
          loadingRef.current.complete()
          Logger.error('Submit Forgot Password', err)
          setAlertType(ALERT_TYPES.UPDATE_FAILED)
          setErrorMessage(err.message || JSON.stringify(err))
        }
      })
    })
  }

  const renderAlert = () => {
    if (alertType === ALERT_TYPES.UPDATE_FAILED) {
      const markup = { __html: errorMessage }
      return <div styleName="alert error" dangerouslySetInnerHTML={markup} />
    }
  }

  if (redirect) {
    return <Redirect id="login-redirect" to="/" />
  }

  return <div styleName="container">
    { renderAlert() }
    <LoadingBar color='#f11946' ref={loadingRef} />

    <div styleName="header">
      <div styleName="logo">
        <h1>Blueleaf</h1>
      </div>

      <div styleName="header-text">
        <h2>Forgot your password?</h2>
      </div>
    </div>

    <div styleName="form-container">
      <form id="forgot-password-form" onSubmit={handleSubmit}>
        <div styleName="form-description">
          If your email address is in our system and confirmed by you,
          you&apos;ll receive a link to reset your password. Should you still need to confirm your email address,
          we&apos;ll send you a confirmation link and then you&apos;ll be able to sign in
        </div>
        <div styleName="fieldset">
          <label styleName="label">
            Your Email Address
          </label>
          <input styleName="input-field" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <input id="submit-btn" styleName="submit-btn" type="submit" value="Reset Password" />
        <Link id="sign-in-btn" styleName="link" to="/">Back to sign in</Link>
      </form>
    </div>

    <Footer />
  </div>
}
