import React, { useEffect } from 'react'
import { signOut, getAuthenticatedUser, setTokens } from '../Auth'
import { signInExternalHostWithCustomRedirect } from '../ExternalHosts'
import Logger from '../Logger'
require('dotenv').config()

export default function RefreshToken (props) {
  const params = new URLSearchParams(window.location.search)
  const returnToUrl = params.get('return_to')
  const cognitoUser = getAuthenticatedUser()

  useEffect(() => {
    cognitoUser.getSession((sessionErr, cognitoTokens) => {
      if (sessionErr) {
        Logger.error('Get session failed', sessionErr)
        signOut()
      } else {
        const refreshToken = cognitoTokens.getRefreshToken()
        cognitoUser.refreshSession(refreshToken, (err, newCognitoTokens) => {
          if (err) {
            Logger.error('Refresh session failed', err)
            signOut()
          } else {
            setTokens(newCognitoTokens)

            // We need to inform the backend about the tokens change so it can update the
            // the cookies with the new tokens. Currently this backend is Mono but later on
            // we will use a dedicated backend for managing these tokens
            //
            // After signin, instead of redirecting to Mono, the app will redirect directly
            // to the url // specified in "?return_to" parameter. This avoid circulating
            // redirection // from A -> Mono -> B -> Mono -> back to A
            signInExternalHostWithCustomRedirect(returnToUrl)
          }
        })
      }
    })
  }, [])

  return <div> </div>
}
