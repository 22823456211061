import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { hasTokens } from './Auth'

export default function PrivateRoute (props) {
  if (hasTokens()) {
    return <Route {...props} >
      {props.children}
    </Route>
  } else {
    return <Redirect to="/" />
  }
}
