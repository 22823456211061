import 'regenerator-runtime/runtime'
import React from 'react'
import { render } from 'react-dom'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import MfaSettings from './MfaSettings'
import ChangePassword from './ChangePassword'
import UpdateEmail from './UpdateEmail'
import ConfirmSignUp from './ConfirmSignUp'
import ResetPassword from './ResetPassword'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import RefreshToken from './RefreshToken'
import SignOut from './SignOut'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { authenticateCognito, hasTokens } from './Auth'
import { setExternalHost } from './ExternalHosts'
import { setAdvisorCustomLogin } from './AdvisorCustomLogin'
import initPendo from './pendo'

setAdvisorCustomLogin()
setExternalHost()

function App () {
  return (
    <Router>
      <Switch>
        <PublicRoute path="/forgot_password">
          <ForgotPassword CognitoIdentityLib={AmazonCognitoIdentity}/>
        </PublicRoute>
        <PublicRoute path="/password_recoveries/new">
          <ForgotPassword CognitoIdentityLib={AmazonCognitoIdentity}/>
        </PublicRoute>
        <PrivateRoute path="/mfa_settings">
          <MfaSettings CognitoIdentityLib={AmazonCognitoIdentity}/>
        </PrivateRoute>
        <PrivateRoute path="/change_password">
          <ChangePassword CognitoIdentityLib={AmazonCognitoIdentity}/>
        </PrivateRoute>
        <PrivateRoute path="/update_email">
          <UpdateEmail CognitoIdentityLib={AmazonCognitoIdentity}/>
        </PrivateRoute>
        <PrivateRoute path="/refresh_token">
          <RefreshToken CognitoIdentityLib={AmazonCognitoIdentity}/>
        </PrivateRoute>
        <Route path="/confirm_sign_up/:username/:confirmationCode">
          <ConfirmSignUp CognitoIdentityLib={AmazonCognitoIdentity}/>
        </Route>
        <Route path="/reset_password/:username/:code">
          <ResetPassword CognitoIdentityLib={AmazonCognitoIdentity}/>
        </Route>
        <Route path="/sign_out">
          <SignOut CognitoIdentityLib={AmazonCognitoIdentity}/>
        </Route>
        <PublicRoute path="/advisor/:advisorId">
          <Login CognitoIdentityLib={AmazonCognitoIdentity}/>
        </PublicRoute>
        <PublicRoute path="/">
          <Login CognitoIdentityLib={AmazonCognitoIdentity}/>
        </PublicRoute>
      </Switch>
    </Router>
  )
}

if (hasTokens()) {
  authenticateCognito(() => {
    initPendo()

    render(
      <App/>,
      document.getElementById('main')
    )
  })
} else {
  render(
    <App/>,
    document.getElementById('main')
  )
}
