import React from 'react'
import { signOut } from '../Auth'

export default function SignOut () {
  signOut()

  return <div>
    Loading
  </div>
}
