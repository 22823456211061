import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './index.css'

export default function AdvisorGenericLogin ({ authenticate, advisor }) {
  const year = new Date().getFullYear()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    authenticate(email, password)
  }

  return <div styleName="generic-container">
    <div styleName="generic-container-inner">
      <div styleName="header">
        <div styleName="pic">
          <img src={advisor.large_photo_full_url} />
        </div>
        <div styleName="brand">
          <div styleName="sign-in-to">
            Sign in to...
          </div>
          <div styleName="advisor-title-container">
            {advisor.practice_name || advisor.full_name || advisor.email}
          </div>
          <div styleName="advisor-name">
            {advisor.full_name_and_advisor_title}
          </div>
        </div>
      </div>

      <div styleName="form-container">
        <form id="generic-login-form" onSubmit={handleSubmit}>
          <div styleName="fieldset">
            <label styleName="label"> Email Address </label>

            <input styleName="input-field" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div styleName="fieldset">
            <label styleName="label"> Password </label>

            <input styleName="input-field" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div styleName="fieldset">
            <input styleName="submit-btn" type="submit" value="Sign in" />
            <Link styleName="link" to="/forgot_password">Forgot your password?</Link>
          </div>

        </form>
        <div styleName="message">
          <div styleName="message-header">
            Welcome back!
          </div>

          <div styleName="message-body">
            Having trouble and need help signing in to your account?
            <a styleName="email-support-link" href="mailto:vipsupport@blueleaf.com"> Email VIP Support </a>
            for assistance.
          </div>
        </div>
      </div>
    </div>

    <div styleName="footer">
      {advisor.subdomain}.blueleaf.com is provided by your financial advisor through Blueleaf. © {year} Blueleaf Wealth, Inc. All rights reserved.
      <br/>
      {advisor.disclaimer}
    </div>
  </div>
}
