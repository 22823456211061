.container {
  overflow: auto;
  overflow-x: auto;
  background: #f8f8f8;
  height: 100% !important;
  color: #333;
}

.header {
  background: #e6f2ff;
  border-bottom: 4px solid #fff;
  padding-bottom: 70px;
  position: relative;
}

.logo {
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 3px double #accfe5;
  position: relative;
  max-width: 660px;
  width: 100%;
  margin: 0 auto;
}
.logo h1{
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  background-image: url(/images/blueleaf.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 133px;
  height: 32px;
}

.header-text {
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
  line-height: 40px;
  max-width: 660px;
}

.header-text h2 {
  font-family: "Crete Round",  Georgia, 'Times New Roman', Times, serif;
  font-size: 28px;
  color: #3f5f7f;
  display: inline;
  clear: both;
  margin-top: 20px;
  margin-bottom: 10px;
}

.header-text p {
  font-family: "Crete Round", Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
  color: #333333;
  display: inline;
  font-style: italic;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #accfe5;
  color: #6baad1;
  text-shadow: 0 1px 1px #fff;
}

.form-container {
  max-width: 660px;
  width: 100%;
  margin: 0 auto;
  -moz-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 0 4px rgb(0 0 0 / 10%);
  -o-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 4px rgb(0 0 0 / 10%);
  border: 1px solid #ccc;
  overflow: hidden;
  *zoom: 1;
  position: relative;
  top: -60px;
  padding: 20px 20px 20px 20px;
  background: #fff;
}

.label {
  display: block;
  width: auto;
  float: none;
  margin-bottom: 5px;
  padding: 0;
  color: #222;
  font-weight: bold;
  font-size: 16px;
}

.input-field {
  max-width: 344px;
  display: inline-block;
  border: 1px solid #ccc;
  padding: 6px;
  width: 100%;
  height: 40px;
}

.fieldset {
  margin-bottom: 25px;
}

.submit-btn {
  cursor: pointer;
  font-family: "helvetica neue", helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #FCFCFC;
  text-shadow: 0px -1px 1px #a64767;
  text-align: center;
  line-height: 1;
  padding: 10px 10px 12px 10px;
  border: none;
  border-radius: 6px;
  background-color: #0079de;
  background-image: -webkit-linear-gradient(top, #0079de 0%, #0079de 50%, #0071cf 51%, #0071cf 100%);
}

.link {
  margin-left: 20px;
  font-size: 12px;
  color: #16a4de;
  text-decoration: none;
}

.form-description {
  margin-bottom: 1em;
  line-height: 1.5em;
  max-width: 40em;
  font-size: 14px;
}

.checkbox {
  margin-left: 5px;
}
