import React, { useState } from 'react'
import '../SharedCss/alert.css'
import '../SharedCss/form.css'
import { useParams, Redirect } from 'react-router-dom'
import ALERT_TYPES from '../SharedConstants/AlertTypes'
import Flash from '../Flash'
require('dotenv').config()

export default function ConfirmSignUp (props) {
  const { CognitoIdentityLib } = props
  const { confirmationCode, username } = useParams()
  const [alertType, setAlertType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const userPool = new CognitoIdentityLib.CognitoUserPool({
    UserPoolId: process.env.USER_POOL_ID,
    ClientId: process.env.COGNITO_APP_ID
  })

  const userData = {
    Username: username,
    Pool: userPool
  }

  const cognitoUser = new CognitoIdentityLib.CognitoUser(userData)

  cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
    if (err) {
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage(err.message || JSON.stringify(err))
    } else {
      Flash.setMessage('User confirmed, you can sign in now.')
      setAlertType(ALERT_TYPES.UPDATE_SUCCESSFULLY)
    }
  })

  if (alertType === ALERT_TYPES.UPDATE_SUCCESSFULLY) {
    return <Redirect id="login-redirect" to='/' />
  }

  if (alertType === ALERT_TYPES.UPDATE_FAILED) {
    return (
      <div styleName="alert error">
        { errorMessage }
      </div>
    )
  }

  return (
    <div>
      Confirming user...
    </div>
  )
}
