import { getAuthenticatedUserData } from './Auth'
require('dotenv').config()

export default async () => {
  const userData = getAuthenticatedUserData()

  // Call this whenever information about your visitors becomes available
  // Please use Strings, Numbers, or Bools for value types.
  const visitor = {
    id: `${userData.getAttr('custom:bl_id')}`,
    email: `${userData.getAttr('email')}`
  }

  const account = {
    id: `${userData.getAttr('custom:firm_id')}`
  }

  pendo.initialize({
    visitor: visitor,
    account: account
  })
}

export function trackPendo (activityName, data) {
  if (process.env.ENV === 'production' || process.env.ENV === 'sandbox') {
    pendo.track(activityName, data)
  }
}
