export default class UserData {
  constructor (userData) {
    this.userData = userData
  }

  getAttr (attrName) {
    const attr = this.userData.UserAttributes.filter(attr => attr.Name === attrName)[0]

    if (attr) {
      return attr.Value
    }
  }

  isSmsMfaActivated () {
    if (this.userData.UserMFASettingList) {
      return this.userData.UserMFASettingList.indexOf('SMS_MFA') !== -1
    } else {
      return false
    }
  }
}
