import React, { useEffect, useState } from 'react'
import { getCustomSignInSetting } from '../ExternalHosts/mono'
import AdvisorGenericLogin from '../AdvisorGenericLogin'
import Logger from '../Logger'
import { clearAdvisorCustomLogin } from '../AdvisorCustomLogin'

import '../SharedCss/alert.css'
import './index.css'

// This is not a typical React component because it is not a controlled one. The html code
// is fed from outside
//
// We store the email & password in normal variables (customLoginEmail, customLoginPassword) ,
// instead of State because we cannot hook the setState method into the inner html, therefor these
// variables must stay outside of the component otherwise they will be reset everytime the component rerenders
//
// The most ugly part is how to handle events from the elements. Again, we cannot hook the react handlers to
// inner html therefor we must hook the event to the parent DIV element and wait for the events from the inner inputs
// to propagate there, it is ugly but it works and thanksfully we do not have very complicated form otherwise it would
// be FEASIBLE

export default function CustomLogin ({ advisorId, authenticate }) {
  const [htmlCode, setHtmlCode] = useState('')
  const [showGenericForm, setShowGenericForm] = useState(false)
  const [showCustomForm, setShowCustomForm] = useState(false)
  const [advisor, setAdvisor] = useState(false)

  const authenticateCustom = () => {
    const emailField = document.querySelectorAll('input[type=email]')[0] ||
                    document.querySelectorAll('input[type=text]')[0]

    const email = emailField.value
    const password = document.querySelectorAll('input[type=password]')[0].value
    authenticate(email, password)
  }

  const handleOnClick = (e) => {
    if (e.target.type === 'submit') {
      e.preventDefault()
      authenticateCustom()
    }
  }

  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') {
      authenticateCustom()
    }
  }

  useEffect(() => {
    getCustomSignInSetting(advisorId).then((response) => {
      if (response.generic === false) {
        setHtmlCode(response.html_code + '<style>' + response.css_code + '</style>')
        setShowCustomForm(true)
      } else {
        setShowGenericForm(true)
        setAdvisor(response.advisor)
      }
    }).catch((err) => {
      Logger.error(`Fetching custom login for advisor ${advisorId}`, err)
      clearAdvisorCustomLogin()
      location.href = '/'
    })
  }, [])

  if (showCustomForm) {
    return <div className="custom" styleName="custom-login-container">
      <div onKeyUp={handleOnKeyUp} onClick={handleOnClick} dangerouslySetInnerHTML={{ __html: htmlCode }}>

      </div>
    </div>
  } else if (showGenericForm) {
    return <AdvisorGenericLogin authenticate={authenticate} advisor={advisor}/>
  } else {
    return <div>
      Loading
    </div>
  }
}
