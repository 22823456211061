.footer {
  font-size: 11px;
  padding: 0 0 20px 0 !important;
  max-width: 660px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.legal {
  color: #aaa;
}

.legal:before {
  content: "©";
  margin-right: 3px;
}
