import React from 'react'
import './footer.css'

export default function Footer () {
  const year = new Date().getFullYear()

  return (
    <div styleName="footer">
      <span styleName="legal">
        {`2010-${year} Blueleaf Wealth, Inc. All rights reserved. Patent pending.`}
      </span>
    </div>
  )
}
