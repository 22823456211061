export default {
  UPDATE_FAILED: 'UPDATE_FAILED',
  UPDATE_SUCCESSFULLY: 'UPDATE_SUCCESSFULLY',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  RESET_PASSWORD_SUCCESSFULLY: 'RESET_PASSWORD_SUCCESSFULLY',
  USER_NOT_CONFIRMED: 'USER_NOT_CONFIRMED',
  LOGIN_SUCCESSFULLY: 'LOGIN_SUCCESSFULLY',
  RESENT_CONFIRMATION: 'RESENT_CONFIRMATION',
  CONFIRM_SIGN_UP_SUCCESSFULLY: 'CONFIRM_SIGN_UP_SUCCESSFULLY',
  FLASH: 'FLASH'
}
