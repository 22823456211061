const setMessage = (message) => {
  sessionStorage.setItem('flashMessage', message)
}

const getMessage = () => {
  const message = sessionStorage.getItem('flashMessage')
  setTimeout(() => {
    sessionStorage.removeItem('flashMessage')
  }, 5000)
  return message
}

export default {
  setMessage,
  getMessage
}
