require('dotenv').config()

const start = (msg, params = {}) => {
  info(`Start ${msg}`, params)
}

const done = (msg, params = {}) => {
  info(`Done ${msg}`, params)
}

const info = (msg, params = {}) => {
  if (process.env.ENV === 'production') {
    return
  }

  console.log('Info:', msg, params)
}

const error = (msg, params = {}) => {
  if (process.env.ENV === 'production') {
    return
  }

  console.log('Error:', msg, params)
}

export default {
  info,
  error,
  start,
  done
}
