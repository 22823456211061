import React, { useState } from 'react'
import './index.css'
import '../SharedCss/alert.css'
import '../SharedCss/form.css'
import { Link } from 'react-router-dom'
import Footer from '../SharedComponents/Footer'
require('dotenv').config()

export default function CredentialsForm ({ authenticate }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault()
    authenticate(email, password)
  }

  return (
    <div>
      <div styleName="header">
        <div styleName="logo">
          <h1>Blueleaf</h1>
        </div>

        <div styleName="header-text">
          <h2>Sign In</h2>
          <p>Welcome back!</p>
        </div>
      </div>

      <div styleName="form-container">
        <form id="login-form" onSubmit={handleSubmit}>
          <div styleName="fieldset">
            <label styleName="label">
              Email Address
            </label>
            <input styleName="input-field" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div styleName="fieldset">
            <label styleName="label">
              Password
            </label>
            <input styleName="input-field" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <input styleName="submit-btn" type="submit" value="Sign In" />
          <Link styleName="link" to="/forgot_password">Forgot your password?</Link>
        </form>
      </div>

      <Footer />
    </div>
  )
}
