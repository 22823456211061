import { getExternalHostUrl } from './'
require('dotenv').config()

const signIn = (tokens) => {
  const params = getRequestParams('POST', tokens)
  return fetch(`${getExternalHostUrl()}/handle_authentication`, params)
}

const signOut = (tokens) => {
  const params = getRequestParams('DELETE', tokens)
  return fetch(`${getExternalHostUrl()}/handle_authentication`, params)
}

const afterSignedIn = () => {
  window.location.href = getExternalHostUrl()
}

export const getCustomSignInSetting = (advisorId) => {
  return fetch(`${getExternalHostUrl()}/api/v1/advisors/${advisorId}/custom_login_setting.json`)
    .then(response => response.json())
}

export const checkCognitoStatus = (email) => {
  return fetch(`${getExternalHostUrl()}/api/v1/check_cognito_status.json?email=${encodeURIComponent(email)}`)
    .then(response => response.json())
}

const getRequestParams = (method, tokens) => {
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      AccessToken: tokens.accessToken,
      Authorization: `Bearer ${tokens.idToken}`,
      RefreshToken: tokens.refreshToken
    },
    credentials: 'include'
  }
}

export default {
  signIn,
  afterSignedIn,
  signOut
}
