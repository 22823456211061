import { getTokens } from '../Auth'
import Logger from '../Logger'
require('dotenv').config()

const putRecord = (tokens, attrs) => {
  Logger.start('Put new record to Message Bus', attrs)

  const metadata = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${tokens.idToken}`
    },
    body: JSON.stringify(attrs)
  }
  return fetch(`${process.env.MESSAGE_BUS_URL}/put_record`, metadata).then(() => {
    Logger.done('Put new record to Message Bus', attrs)
  }).catch((err) => {
    Logger.error('Put new record to Message Bus', err)
  })
}

const emitUserAttrsChange = (attrs) => {
  const tokens = getTokens()
  attrs.message_type = 'attr_change'
  return putRecord(tokens, attrs)
}

export default {
  emitUserAttrsChange
}
