import UserData from './UserData'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { signOutExternalHost } from './ExternalHosts'
import Logger from './Logger'
import { clearAdvisorCustomLogin } from './AdvisorCustomLogin'
require('dotenv').config()

export function authenticateCognito (callback) {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: process.env.USER_POOL_ID,
    ClientId: process.env.COGNITO_APP_ID
  })

  const cognitoUser = userPool.getCurrentUser()

  if (cognitoUser === undefined || cognitoUser === null) {
    Logger.error('Cognito user not found')
    signOut()
  } else {
    cognitoUser.getSession((errSession, cognitoTokens) => {
      if (errSession) {
        Logger.error('Get session failed', errSession)
        signOut()
      }
      // Cognito JS library refresh token implicitly so we don't have to it ourselves,
      // however we need to set the token back into our localstorage to use later
      setTokens(cognitoTokens)

      cognitoUser.getUserData((err, data) => {
        if (err) {
          Logger.error('Load user data failed', err)
          signOut()
        } else {
          Logger.info('Load user data successfully')
          setAuthenticatedUser(cognitoUser, data)
          callback()
        }
      }, { bypassCache: true })
    })
  }
}

export function fetchUserData () {
  Logger.start('Fetch user data')
  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: process.env.USER_POOL_ID,
    ClientId: process.env.COGNITO_APP_ID
  })
  const cognitoUser = userPool.getCurrentUser()

  cognitoUser.getSession(() => {
    cognitoUser.getUserData((err, data) => {
      if (err) {
        Logger.error('Fetch user data', err)
      } else {
        Logger.done('Fetch user data', data)
        setAuthenticatedUser(cognitoUser, data)
      }
    }, { bypassCache: true })
  })
}

export function hasTokens () {
  return localStorage.getItem('accessToken') !== null
}

export function setAuthenticatedUser (cognitoUser, rawUserData) {
  Logger.info('Set Authenticated user', cognitoUser)
  window.authenticatedUser = cognitoUser
  Logger.info('Set Authenticated user data', rawUserData)
  window.authenticatedUserData = new UserData(rawUserData)
}

export function getAuthenticatedUser () {
  return window.authenticatedUser
}

export function getAuthenticatedUserData () {
  return window.authenticatedUserData
}

export function signIn (tokens) {
  setTokens(tokens)
}

export function getIdToken () {
  return localStorage.getItem('idToken')
}

export function getAccessToken () {
  return localStorage.getItem('accessToken')
}

export function getRefreshToken () {
  return localStorage.getItem('refreshToken')
}

function signOutReactApp () {
  // Signout client side
  clearTokens()
  clearAdvisorCustomLogin()

  if (getAuthenticatedUser() !== null && getAuthenticatedUser() !== undefined) {
    getAuthenticatedUser().signOut()
  }
}

export function signOut () {
  Logger.start('Sign out')

  // Signout all external hosts
  return signOutExternalHost().then(() => {
    Logger.done('Sign out')
    signOutReactApp()
    const params = new URLSearchParams(window.location.search)
    const advisorId = params.get('advisor_id')
    let url = '/'

    if (advisorId) {
      // After signed out, if user is still on a subdomain ( can be detected by the query param "advisor_id" )
      // then we want to render the custom login page
      url += `?advisor_id=${advisorId}`
    }

    location.href = url
  }).catch((err) => {
    Logger.done('Sign out external host', err)
    signOutReactApp()
    location.href = '/'
  })
}

export function setTokens (tokens) {
  localStorage.setItem('idToken', tokens.idToken.jwtToken)
  localStorage.setItem('refreshToken', tokens.refreshToken.token)
  localStorage.setItem('accessToken', tokens.accessToken.jwtToken)
}

export function clearTokens (tokens) {
  localStorage.removeItem('idToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('accessToken')
}

export function getTokens () {
  return {
    idToken: localStorage.getItem('idToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    accessToken: localStorage.getItem('accessToken')
  }
}
