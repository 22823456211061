export function formatPhoneNumber (phoneNumber) {
  phoneNumber = phoneNumber.trim()

  if (phoneNumber.length === 0) {
    return phoneNumber
  } else if (phoneNumber.charAt(0) === '+') {
    // return as is if a country code is given
    return phoneNumber
  } else {
    // Assume it is American phone number it no country code is given
    if (phoneNumber.charAt(0) === '1') {
      return `+${phoneNumber}`
    } else {
      return `+1${phoneNumber}`
    }
  }
}
