import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { hasTokens } from './Auth'

export default function PublicRoute (props) {
  if (hasTokens()) {
    return <Redirect to="/mfa_settings" />
  } else {
    return <Route {...props} >
      {props.children}
    </Route>
  }
}
