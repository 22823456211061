.alert {
  box-shadow: inset 0 2px 4px rgb(255 255 255 / 20%);
  -webkit-box-shadow: 0 2px 2px rgb(0 0 0 / 13%);
  -moz-box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.2);
  overflow: hidden;
  margin: 0;
  padding: 2px 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 30px;
  line-height: 30px;
  z-index: 9999;
  opacity: 0.95;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
}

.alert.render-with-menu {
  left: 160px;
}

.alert.error {
  color: #333;
  background: #ffe8a7;
  background-color: lemonchiffon;
  background: -moz-linear-gradient(lemonchiffon, #ffe8a7);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(lemonchiffon), to(#ffe8a7));
  border-top: 1px solid #fef4d8;
  border-bottom: 1px solid #ebc38c;
}

.alert.success {
  color: #300b30;
  background-color: #84cc87;
  background: -moz-linear-gradient(#84cc87, #5cad55);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#84cc87), to(#5cad55));
  border-top: 1px solid #ace8a7;
  border-bottom: 1px solid #607e5f;
}
