import MonoHost from './mono'
import Logger from '../Logger'
import { getTokens } from '../Auth'
require('dotenv').config()

const SUPPORTED_EXTERNAL_HOSTS = ['mono']
const DEFAULT_EXTERNAL_HOST = 'mono'

const getExternalHostName = () => {
  const externalHostName = localStorage.getItem('externalHostName')
  if (SUPPORTED_EXTERNAL_HOSTS.indexOf(externalHostName) === -1) {
    return DEFAULT_EXTERNAL_HOST
  } else {
    return externalHostName
  }
}

const getExternalHost = () => {
  const externalHostName = getExternalHostName()
  const externalHost = {
    mono: MonoHost
  }[externalHostName] || MonoHost

  Logger.info('External Host', { name: externalHostName, object: externalHost })
  return externalHost
}

export function getExternalHostUrl () {
  return localStorage.getItem('externalHostUrl') || process.env.MONO_HOST_URL
}

export function signInExternalHost () {
  Logger.start('Sign in external Host')
  const externalHost = getExternalHost()

  return externalHost.signIn(getTokens()).then((response) => {
    Logger.done('Sign in external Host')
    externalHost.afterSignedIn()
  }).catch((error) => {
    Logger.error('sign in external host', error)
  })
}

export function signOutExternalHost () {
  Logger.start('Sign out external Host')
  return getExternalHost().signOut(getTokens()).then(() => {
    Logger.done('Sign out external Host')
  }).catch((error) => {
    Logger.error('sign out external host', error)
  })
}

export function setExternalHost () {
  const params = new URLSearchParams(window.location.search)
  const externalHostName = params.get('external_host')
  let externalHostUrl = params.get('external_host_url')

  if (externalHostName) {
    if (externalHostUrl.indexOf('localhost') === -1) {
      externalHostUrl = externalHostUrl.replace('http', 'https')
    }

    Logger.info('set External Host', { name: externalHostName, url: externalHostUrl })
    localStorage.setItem('externalHostName', externalHostName)
    localStorage.setItem('externalHostUrl', externalHostUrl)
  }
}

export function signInExternalHostWithCustomRedirect (url) {
  const externalHost = getExternalHost()

  return externalHost.signIn(getTokens()).then((response) => {
    window.location.href = url
  }).catch((error) => {
    Logger.error('sign in external host', error)
  })
}
