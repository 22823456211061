import React from 'react'
import { Link } from 'react-router-dom'
import { signOut, getAuthenticatedUserData } from '../Auth'
import './index.css'
import { signInExternalHost } from '../ExternalHosts'

export default function MyMenu (props) {
  const { highlighted } = props
  const userData = getAuthenticatedUserData()

  const handleSignOut = () => {
    signOut()
  }

  // Signing in again to external host just in case the session has expired over there. The idea
  // is as long as the Cognito token is still valid in the front end app, client should be able to
  // navigate with a valid session to External Host
  const redirectExternalHost = () => {
    signInExternalHost()
  }

  return (
    <div styleName="menu-container">
      <div>
        <div styleName="menu-item">
          <a onClick={ () => { redirectExternalHost() } }> Back to app</a>
        </div>

        <div styleName={highlighted === 'update_email' ? 'highlighted menu-item' : 'menu-item'}>
          <Link to="/update_email"> Email</Link>
        </div>

        <div styleName={highlighted === 'change_password' ? 'highlighted menu-item' : 'menu-item'}>
          <Link to="/change_password"> Change Password </Link>
        </div>

        <div styleName={highlighted === 'mfa_settings' ? 'highlighted menu-item' : 'menu-item'}>
          <Link to="/mfa_settings"> Mfa Settings</Link>
        </div>

        <div styleName="menu-item last-menu-item">
          <a onClick={ () => { handleSignOut() } }> Sign Out</a>
        </div>

        <div styleName="account-name">
          Editing details for
          <b> { userData.getAttr('email') } </b>
        </div>
      </div>
    </div>
  )
}
