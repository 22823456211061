import React, { useState, useRef } from 'react'
import '../SharedCss/alert.css'
import '../SharedCss/form.css'
import { Redirect, Link, useParams } from 'react-router-dom'
import Flash from '../Flash'
import Logger from '../Logger'
import ALERT_TYPES from '../SharedConstants/AlertTypes'
import Footer from '../SharedComponents/Footer'
import LoadingBar from 'react-top-loading-bar'
require('dotenv').config()

export default function ResetPasswordForm (props) {
  const { code, username } = useParams()
  const { CognitoIdentityLib } = props
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [alertType, setAlertType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [redirect, setRedirect] = useState(false)
  const loadingRef = useRef(null)

  const handleSubmit = (e) => {
    Logger.start('Submit Reset Password', { code: code, username: username })
    setAlertType('')
    loadingRef.current.continuousStart()
    e.preventDefault()

    const userPool = new CognitoIdentityLib.CognitoUserPool({
      UserPoolId: process.env.USER_POOL_ID,
      ClientId: process.env.COGNITO_APP_ID
    })

    const userData = {
      Username: username,
      Pool: userPool
    }

    const cognitoUser = new CognitoIdentityLib.CognitoUser(userData)

    if (password !== confirmation) {
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage('Password and confirmation password do not match')
      return
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: (result) => {
        loadingRef.current.complete()
        Logger.done('Submit Reset Password', { code: code, username: username })

        Flash.setMessage('Your password has been reset, please sign in with the new password')
        setRedirect(true)
      },
      onFailure: (err) => {
        loadingRef.current.complete()
        Logger.error('Submit Reset Password', err)
        setAlertType(ALERT_TYPES.UPDATE_FAILED)
        setErrorMessage(err.message)
      }
    })
  }

  const renderAlert = () => {
    if (alertType === ALERT_TYPES.UPDATE_FAILED) {
      return (
        <div styleName="alert error">
          { errorMessage }
        </div>
      )
    }
  }

  if (redirect) {
    return <Redirect id="login-redirect" to="/" />
  }

  return <div styleName="container">
    { renderAlert() }
    <LoadingBar color='#f11946' ref={loadingRef} />

    <div styleName="header">
      <div styleName="logo">
        <h1>Blueleaf</h1>
      </div>

      <div styleName="header-text">
        <h2>Reset your password</h2>
      </div>
    </div>

    <div styleName="form-container">
      <form id="reset-password-form" onSubmit={handleSubmit}>
        <div styleName="fieldset">
          <label styleName="label">
            Choose a new password
          </label>
          <input styleName="input-field" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>

        <div styleName="fieldset">
          <label styleName="label">
            Confirm your new password
          </label>
          <input styleName="input-field" type="password" value={confirmation} onChange={(e) => setConfirmation(e.target.value)} />
        </div>

        <input id="submit-btn" styleName="submit-btn" type="submit" value="Reset Password" />
        <Link id="sign-in-btn" styleName="link" to="/">Back to sign in</Link>
      </form>
    </div>

    <Footer />
  </div>
}
